
import Teleport from 'vue2-teleport';
import { Button, Dialog } from 'element-ui';
import { InfoIcon, ChevronRightIcon, CheckIcon } from 'vue-feather-icons';
import { LoyaltyService } from '@/helpers/LoyaltyService';
export default {
  props: { oneEvent: { type: Object } },
  data() {
    return {
      showed: false,
      loading: false,
      loaded: false,
    };
  },
  computed: {
    canUse() {
      return this.oneEvent?.LoyaltyProgramEventCustomer?.CanUse;
    },
    totalToEarn() {
      return this.oneEvent?.LoyaltyProgramEventCustomer?.TotalEarn;
    },
  },
  methods: {
    scrollTo() {
      window.scrollTo({
        top: 600,
        behavior: 'smooth',
      });
    },
    openLoyaltyProgramLink() {
      if (process.client) {
        window.open(
          this.oneEvent?.LoyaltyProgramEventButton?.LoyaltyProgramEventLinkURL,
          '_blank',
          'noreferrer'
        );
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.loaded = true;
        }, 3000);
      }
    },
    onDrawerClose() {
      if (this.oneEvent.LoyaltyProgramEventType.Label === 'social') {
        this.obtainEvent();
      }
    },
    obtainEvent() {
      this.loading = true;
      const loyaltyService = new LoyaltyService(this.$http);

      loyaltyService
        .obtainEvent({
          idLoyaltyProgramEvent: this.oneEvent.IdLoyaltyProgramEvent,
          languageIsoCode: this.$i18n.locale,
        })
        .then((data) => {
          this.$store.dispatch('shop/loyalty/fetchLoyaltyCustomer');
          this.$store.dispatch('shop/loyalty/fetchEvents');
          this.$store.dispatch('shop/loyalty/fetchRewards');
          this.$store.dispatch('shop/loyalty/fetchMyRewards', {
            loyaltyRewardState: 'all',
          });

          this.$notify({
            title: this.$t('Success'),
            message: this.$t(
              'Your reward has been successfully added to your account.'
            ),
            type: 'success',
          });
        })
        .catch((err) => {})
        .finally(() => {
          this.loading = false;
          this.loaded = false;
          this.showed = false;
        });
    },
  },
  components: {
    Button,
    Teleport,
    Dialog,
    InfoIcon,
    CheckIcon,
    ChevronRightIcon,
  },
};
