
import cartMixin from '@/mixins/cartMixin';
import addressMixin from '@/mixins/addressMixin';
import PayzenPayment from '@/services/payments/PayzenPayment';

export default {
  name: 'PayzenForm',
  mixins: [cartMixin, addressMixin],
  data() {
    return {
      error: null,
      formToken: null,
      publicKey: process.env.PAYZEN_PUBLIC_KEY,
      paymentService: null,
      isInitialized: false,
      loading: true,
    };
  },
  computed: {
    currentLocale() {
      return this.$i18n?.locale || 'fr';
    },
  },
  created() {
    // Initialiser le service de paiement dans created pour éviter les problèmes de réactivité
    this.paymentService = new PayzenPayment({
      cart: this.cart,
      auth: this.$auth,
      http: this.$http,
      addressService: this.addressService,
    });

    // Stocker l'objet KR en dehors du système de réactivité de Vue
    this._KR = null;
  },
  mounted() {
    // Utiliser mounted au lieu de async mounted pour éviter les problèmes de cycle de vie
    this.loadKRScript();

    // Ajouter un style pour corriger le problème de positionnement
    this.addFixPositionStyle();
  },
  methods: {
    addFixPositionStyle() {
      // Créer une feuille de style pour neutraliser le style problématique
      const style = document.createElement('style');
      style.textContent = `
        .kr-embedded.kr-out-of-view-form, 
        .kr-embedded.kr-out-of-view-form[krvue], 
        .kr-embedded.kr-out-of-view-form[krvue][kr-popin], 
        .kr-smart-form.kr-out-of-view-form, 
        .kr-smart-form.kr-out-of-view-form[krvue], 
        .kr-smart-form.kr-out-of-view-form[krvue][kr-popin] {
          position: static !important;
          top: auto !important;
          left: auto !important;
          right: auto !important;
          bottom: auto !important;
        }
      `;
      document.head.appendChild(style);
    },
    loadKRScript() {
      return new Promise((resolve, reject) => {
        this.loading = true;
        
        if (typeof KR !== 'undefined') {
          this.initializePayzen();
          resolve();
          return;
        }

        const script = document.createElement('script');
        script.src =
          'https://static.payzen.eu/static/js/krypton-client/V4.0/stable/kr-payment-form.min.js';
        script.async = true;
        script.onload = () => {
          const style = document.createElement('link');
          style.rel = 'stylesheet';
          style.href =
            'https://static.payzen.eu/static/js/krypton-client/V4.0/ext/neon-reset.min.css';
          document.head.appendChild(style);

          setTimeout(() => {
            this.initializePayzen();
            resolve();
          }, 300);
        };
        script.onerror = () => {
          this.error = this.$t('Failed to load payment form');
          this.loading = false;
          reject();
        };
        document.head.appendChild(script);
      });
    },
    async initializePayzen() {
      if (this.isInitialized) {
        this.loading = false;
        return;
      }

      try {
        if (typeof KR === 'undefined') {
          this.error = this.$t('Payment system not loaded');
          this.loading = false;
          return;
        }

        try {
          // Obtenir le token du formulaire
          this.formToken = await this.paymentService.formToken();
          console.log('formToken obtained:', this.formToken);

          if (!this.formToken) {
            this.error = this.$t('Failed to get payment token');
            this.loading = false;
            return;
          }
        } catch (tokenError) {
          console.error('Error getting form token:', tokenError);
          this.error = this.$t('Failed to get payment token');
          this.loading = false;
          return;
        }

        // Configuration de base pour le formulaire de carte
        const config = {
          formToken: this.formToken,
          'kr-language': this.currentLocale,
          'kr-public-key': this.publicKey,
          'kr-theme': 'classic',
          'kr-placeholder-pan': '4970 1000 0000 0000',
          'kr-placeholder-expiry': 'MM/YY',
          'kr-placeholder-security-code': '123',
          'kr-hide-debug-message': true,
        };

        console.log('Initializing PayZen with config:', config);

        try {
          // Utiliser Object.defineProperty pour éviter la réactivité de Vue
          const result = await KR.setFormConfig(config);

          // Stocker KR dans une propriété non-réactive
          this._KR = result.KR;

          // Utiliser addForm sans .kr-embedded
          await this._KR.addForm('#payzenForm');
          console.log('Form added successfully');

          // Configurer les gestionnaires d'événements
          this._KR.onSubmit((event) => {
            console.log('Payment submitted:', event);

            const orderDetails = event?.clientAnswer?.orderDetails;
            const orderId = orderDetails?.orderId;

            this.$gtag.event('add_payment_info', {
              payment_type: 'Card - PayZen',
            });

            this.$router.push({
              path: this.localePath(
                `/cart/thanks/accepted?orderid=${orderId}&cartid=${this.cartService.idCart}`
              ),
            });

            this.$emit('payment-submitted', event);
          });

          this._KR.onError((event) => {
            console.log('Payment error:', event);
            this.error = event.errorMessage;
            this.$emit('payment-error', event);
          });

          this.isInitialized = true;
          
          // Set loading to false after form is fully initialized
          setTimeout(() => {
            this.loading = false;
          }, 500);
        } catch (initError) {
          console.error('PayZen initialization error:', initError);
          this.error = this.$t('Failed to initialize payment form');
          this.loading = false;
        }
      } catch (err) {
        console.error('PayZen setup error:', err);
        this.error = this.$t(
          'An error occurred while setting up the payment form'
        );
        this.loading = false;
      }
    },
    async submitPayment() {
      if (!this._KR) {
        this.error = this.$t('Payment form not initialized');
        return;
      }

      try {
        await this._KR.submit();
      } catch (err) {
        console.error('Payment submission error:', err);
        this.error = this.$t('Failed to submit payment');
      }
    },
  },
};
