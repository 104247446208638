
import { Progress } from 'element-ui';
import { Button, Collapse, CollapseItem } from 'element-ui';
import CartItems from '@/pages/cart/components/CartItems.vue';
import DutyInformation from '@/pages/cart/components/DutyInformation.vue';
import CardInfo from '@/pages/cart/components/CardInfo.vue';
import TotalOrder from '@/pages/cart/components/TotalOrder.vue';
import TunnelOrderLayout from './TunnelOrderLayout.vue';
import CodePromo from './CodePromo.vue';
import CodePromo2 from './CodePromo2.vue';
import { ProductHelpers } from '@/helpers/ProductHelpers.js';
import cartMixin from '@/mixins/cartMixin.js';
import { LoyaltyService } from '@/helpers/LoyaltyService';
import LoayltyPayment from './LoayltyPayment.vue';

export default {
  mixins: [cartMixin],
  data() {
    return {
      posBoxA: 0,
      posBoxB: 0,
      hasError: false,
      productSimilar: [],

      loyalty: null,
    };
  },
  computed: {
    productCartSize() {
      return this.cart?.Total?.Product?.Quantity;
    },
    disableBtn() {
      return this.productCartSize === 0 || this.hasError;
    },
    totalToPay() {
      return this.cart?.Total?.ToPay?.TaxIncl;
    },
    currencySign() {
      return this.$localisation.currencySign;
    },
    showLoyalty() {
      return this.loyalty?.LoyaltyRewards.length;
    },
  },
  methods: {
    next() {
      // this.$emit("onCommandeClick");
      this.$store.commit('shared/wind/setLoginGoTo', '/cart/delivery');
      if (this.$auth.loggedIn) {
        this.$router.push(this.localePath('/cart/delivery'));
      } else {
        // this.$router.push(this.localePath("/authenticate/login?redirect=2"));
        this.$router.push(this.localePath('/cart/connection'));
      }

      this.$gtag.event('begin_checkout', {
        currency: this.currencySign,
        value: this.totalToPay,
      });
    },
    checkHasError($event) {
      this.hasError = $event;
    },

    loadProductSimilar() {
      const productHelpers = new ProductHelpers(this.$http, this);

      const lastItem = this.cart?.Products[0];

      if (lastItem) {
        const permalink = String(lastItem.IdProduct);
        try {
          productHelpers
            .getProductstSimilar(permalink)
            .then((response) => {
              if (Array.isArray(response)) {
                this.productSimilar = response;
              }
            })
            .catch((error) => {
              console.error(error);
            });
        } catch (error) {}
      }
    },
  },
  created() {
    this.loadProductSimilar();
  },
  mounted() {
    const loyaltyService = new LoyaltyService(this.$http);

    loyaltyService
      .getListMyRewards({
        languageIsoCode: this.$i18n.locale,
        currencyIsoCode: this.$localisation.appCurrency.CurrencyIsoCode,
        loyaltyRewardState: 'available',
      })
      .then((data) => {
        this.loyalty = data;
      });
  },
  components: {
    DutyInformation,
    CartItems,
    Progress,
    Button,
    Collapse,
    CollapseItem,
    CardInfo,
    TotalOrder,
    TunnelOrderLayout,
    CodePromo,
    CodePromo2,
    LoayltyPayment,
  },
};
