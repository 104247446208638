
import {
  Collapse,
  CollapseItem,
  Skeleton,
  SkeletonItem,
  Radio,
} from 'element-ui';
import TotalOrder from '@/pages/cart/components/TotalOrder.vue';
import MiniCart from '@/pages/cart/components/MiniCart.vue';
import DeliverySelected from '@/pages/cart/components/DeliverySelected.vue';
import TunnelOrderLayout from './TunnelOrderLayout.vue';

import BancCardHipay from '@/pages/cart/components/payments/hipay/BancCardHipay';
import BancCardHipayHeader from '@/pages/cart/components/payments/hipay/BancCardHipay/BancCardHipayHeader.vue';
import Sofort from './payments/hipay/Sofort';
import SofortHeader from '@/pages/cart/components/payments/hipay/Sofort/SofortHeader.vue';
import Bancontact from './payments/hipay/Bancontact';
import BancontactHeader from '@/pages/cart/components/payments/hipay/Bancontact/BancontactHeader.vue';
import Ideal from './payments/hipay/Ideal';
import IdealHeader from '@/pages/cart/components/payments/hipay/Ideal/IdealHeader.vue';
import Giropay from './payments/hipay/Giropay';
import GiropayHeader from '@/pages/cart/components/payments/hipay/Giropay/GiropayHeader.vue';
import PayPalForm from './payments/paypal/PayPalForm';
import PayPalFormHeader from './payments/paypal/PaypalFormHeader.vue';
import KlarnaForm from './payments/klarna/KlarnaForm';
import KlarnaFormHeader from './payments/klarna/KlarnaFormHeader.vue';
import AlmaForm from './payments/alma/AlmaForm';
import AlmaFormHeader from './payments/alma/AlmaFormHeader.vue';
import StripeForm from './payments/stripe/StripeForm';
import StripeFormHeader from './payments/stripe/StripeFormHeader.vue';

import PayzenFormHeader from './payments/payzen/PayzenFormHeader.vue';
import PayzenForm from './payments/payzen/PayzenForm.vue';

import { PaymentMethods } from '@/services/payments/PaymentMethods';
import addressMixin from '@/mixins/addressMixin.js';
import carriersMixin from '@/mixins/carriersMixin.js';
import LoayltyPayment from './LoayltyPayment.vue';

// import { LoyaltyService } from '@/helpers/LoyaltyService';

export default {
  mixins: [addressMixin, carriersMixin],
  head() {
    return {
      script: [
        // {
        //   hid: 'hipay',
        //   src: '/assets/js/hipay-sdk.js',
        //   defer: true,
        //   // Changed after script load
        //   callback: () => {
        //     this.isHiPayLoaded = true;
        //   },
        // },
        // {
        //   hid: 'stripe',
        //   src: 'https://js.stripe.com/v3/',
        //   defer: true,
        //   // Changed after script load
        //   callback: () => {
        //     this.isStripeLoaded = true;
        //   },
        // },
      ],
    };
  },
  data() {
    return {
      cb: 'CB',
      sofort: 'SOFORT',
      paypal: 'PAYPAL',

      alma: 'ALMA',
      alma1x: 'ALMA1X',
      alma2x: 'ALMA2X',
      alma3x: 'ALMA3X',
      alma4x: 'ALMA4X',

      bancontact: 'BCMC',
      ideal: 'IDEAL',
      giropay: 'GIROPAY',

      klarna: 'KLARNA',
      klarna1x: 'KLARNA1X',
      klarna2x: 'KLARNA2X',
      klarna3x: 'KLARNA3X',

      stripe: 'STRIPE',
      payzen: 'PAYZEN',

      // Set default activePayments to STRIPE

      activePayments: '',

      isHiPayLoaded: false,
      isStripeLoaded: false,
      paymentMethods: new PaymentMethods({ http: this.$http }),
      paymentMethodsData: null,

      // loyalty: null,

      activeCollapse: ['payments'],
    };
  },
  computed: {
    showLoyalty() {
      return this.loyalty?.LoyaltyRewards.length;
    },
  },
  methods: {
    getPaymentPosition(pM) {
      try {
        return this.getPaymentMethod(pM).Position;
      } catch (error) {}

      return 999;
    },
    hasPaymentMethod(paymentCode) {
      return this.paymentMethodsData?.PaymentMethods?.some(
        (item) => item.PaymentCode === paymentCode
      );
    },
    async fetchPaymentMethods() {
      try {
        if (this.idAddressDelivery) {
          const res = await this.paymentMethods.list({
            idAddress: this.idAddressDelivery,
          });
          this.paymentMethodsData = res;

          if (this.paymentMethodsData?.PaymentMethods) {
            this.activePayments =
              this.paymentMethodsData?.PaymentMethods?.[0]?.PaymentCode || '';
          }

          return res;
        }
      } catch (error) {}

      return null;
    },
    getPaymentMethod(name) {
      return this.paymentMethodsData?.PaymentMethods?.find(
        (item) => item.PaymentCode === name
      );
    },
    scrollTo(ref) {
      if (process.client) {
        window.scrollTo({
          top: ref.offsetTop - 60,
          behavior: 'smooth',
        });
      }
    },
    handleCollapseChange(name) {
      if (typeof name === 'string') {
        setTimeout(() => {
          this.$nextTick(() => {
            const collapseItem = this.$refs['collapseItem' + name];
            if (collapseItem && collapseItem.$el) {
              const topPosition =
                collapseItem.$el.getBoundingClientRect().top +
                window.pageYOffset;
              if (process.client) {
                window.scrollTo({
                  top: topPosition - 100,
                  behavior: 'smooth',
                });
              }
            }
          });
        }, 300);
      }
    },
  },
  created() {
    this.addressService.fetchAddresses({
      onSuccess: (data) => {
        this.isOpenAddAddress = this.addresses?.length === 0;
        this.carriersService.fetchCarrierTypes({
          idAddress: this.idAddressDelivery,
          onSuccess: (data) => {
            if (this.carrierTypes) {
              this.carriersService.fetchCarriers({
                idAddress: this.idAddressDelivery,
                carrierType: this.carrierTypes[0],
                responseLevel: 'details',
              });
              this.$store.commit('carriers/setActiveTab', this.carrierTypes[0]);
            }
            this.fetchPaymentMethods();
          },
        });
      },
    });
  },
  mounted() {
    // const loyaltyService = new LoyaltyService(this.$http);
    // loyaltyService
    //   .getListMyRewards({
    //     languageIsoCode: this.$i18n.locale,
    //     currencyIsoCode: this.$localisation.appCurrency.CurrencyIsoCode,
    //     loyaltyRewardState: 'available',
    //   })
    //   .then((data) => {
    //     this.loyalty = data;
    //   });
  },
  components: {
    TotalOrder,
    Collapse,
    CollapseItem,
    TunnelOrderLayout,
    DeliverySelected,
    MiniCart,
    Skeleton,
    SkeletonItem,
    Radio,
    // method option components
    BancCardHipay,
    BancCardHipayHeader,
    SofortHeader,
    Sofort,
    Bancontact,
    BancontactHeader,
    Ideal,
    IdealHeader,
    Giropay,
    GiropayHeader,
    PayPalForm,
    PayPalFormHeader,
    KlarnaForm,
    KlarnaFormHeader,
    AlmaForm,
    AlmaFormHeader,
    StripeForm,
    StripeFormHeader,
    LoayltyPayment,
    PayzenFormHeader,
    PayzenForm,
  },
};
