

import {
  PackageIcon,
  CreditCardIcon,
  CornerUpRightIcon,
  CornerDownLeftIcon,
} from "vue-feather-icons";
import cartMixin from "@/mixins/cartMixin.js";
export default {

  methods:{

    getDeliveryFeeFreeFrom(){
      // if (this.$i18n.locale === "en") {
      //   return 150;
      // }
      // if (this.$i18n.locale === "de") {
      //   return 150;
      // }
      // if (this.$i18n.locale === "fr") {
      //   return 79;
      // }
      return 99
    }
  },

  computed: {
    getDeliveryFeeFreeFromFormatted() {
      const fee = this.getDeliveryFeeFreeFrom();
      return fee;
    },
    currencySign() {
      return this.$localisation.currencySign;
    }
  },
  
  components: {
    PackageIcon,
    CreditCardIcon,
    CornerUpRightIcon,
    CornerDownLeftIcon,
  },
};
