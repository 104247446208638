
import { Alert, Button, Skeleton } from 'element-ui';
import { ProductHelpers } from '@/helpers/ProductHelpers';

export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
    size: {
      required: true,
      type: String,
    },
    idProductAttribute: {
      required: true,
      type: String | Number,
    },
  },
  data() {
    return {
      loading: false,
      shopSelected: null,
      shops: [],
      valide: true,
    };
  },
  computed: {
    productColor() {
      try {
        return this.product.Variant[0].Combination.Attributes.find(
          (item) => item.GroupName === 'Color'
        ).AttributeName;
      } catch (error) {}
      return '';
    },
  },
  methods: {
    isSelected(shop) {
      if (this.shopSelected) {
        return shop.IdStore === this.shopSelected.IdStore;
      }
      return false;
    },
    onSelectAddress($event, shop) {
      if (!$event.disabled) {
        this.shopSelected = shop;
        this.valide = true;
      }
    },
    next() {
      if (this.shopSelected === null) {
        this.valide = false;
        return;
      }
      this.$emit('next', {
        shop: this.shopSelected,
      });
    },
    change(step) {
      this.$emit('change', {
        step,
      });
    },
  },
  async mounted() {
    const productHelpers = new ProductHelpers(this.$http, this);
    try {
      const response = await productHelpers.reservationShopAvailability(
        this.product.IdProduct,
        this.idProductAttribute
      );

      this.shops = response.StockInStore;
    } catch (error) {
    } finally {
      this.loading = false;
    }
  },
  components: {
    Button,
    Skeleton,
    Alert,
  },
};
