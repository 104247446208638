
export default {
  props: {
    link: {
      type: String,
      default: '',
    },
    href: {
      type: String,
      default: '',
    },
    blank: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getLink() {
      if (this.link) {
        return `${process.env.DASHBOARD_URL}${this.link}`;
      }
      if (this.href) {
        return this.href;
      }
      return '';
    },
  },
};
