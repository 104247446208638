
import { Input, Button, Form, FormItem } from 'element-ui';
import { CreditCardIcon, EyeIcon, EyeOffIcon } from 'vue-feather-icons';
import SocialLoginBtns from '@/pages/authenticate/components/SocialLoginBtns.vue';
import cartMixin from '@/mixins/cartMixin.js';
import favoriteMixin from '@/mixins/favoriteMixin.js';
import CustomerService from '@/services/CustomerService';

export default {
  mixins: [cartMixin, favoriteMixin],
  auth: 'guest',
  props: {
    registerLink: {
      type: String,
      default: '/authenticate/register-info',
    },
    redirectToLink: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      userSession: {},
      messageError: '',
      showPass: false,
      customerService: new CustomerService({
        http: this.$http,
        auth: this.$auth,
        store: this.$store,
      }),
      error: '',
      login: {
        user: '',
        pass: '',
      },
      rules: {
        user: [
          {
            required: true,
            message: this.$t('Required Field'),
            trigger: 'blur',
          },
          {
            type: 'email',
            message: this.$t('Please input correct email address'),
            trigger: 'blur',
          },
        ],
        pass: [
          {
            required: true,
            message: this.$t('Required Field'),
            trigger: 'blur',
          },
          {
            required: true,
            message: this.$t('Required Field'),
            trigger: 'blur',
          },
        ],
      },
    };
  },

  mounted() {},
  methods: {
    async connnexion() {
      this.customerService
        .loginWithEmailAndPassword({
          user: this.login.user,
          password: this.login.pass,
        })
        .then((data) => {
          this.hideRightBar();
          this.$gtag.event('login', { method: 'Email et mot de passe' });
          this.rdirectTo();
          this.$notify({
            title: this.$t('welcome_connected.title'),
            message: this.$t('welcome_connected.message'),
            type: 'success',
          });
        })
        .catch((err) => {
          this.error = this.$t(err.response.data.message);
        });
    },
    async onSubmit() {
      this.error = '';
      this.$refs['loginForm'].validate((valid) => {
        if (valid) {
          this.connnexion();
        } else {
          return false;
        }
      });
    },
    hideRightBar() {
      this.$store.commit('shared/menu/toggle', {
        tabActive: 1,
        open: false,
      });
    },
    rdirectTo() {
      // let redirectTo = { name: 'account' };

      if (this.redirectToLink) {
        let redirectTo = this.redirectToLink;
        // } else {
        //   if (this.$route.query.redirect) {
        //     if (this.$route.query.redirect === 'loyalty-program') {
        //       redirectTo = `/account/loyalty/membership`;
        //     } else {
        //       redirectTo = '/cart/delivery';
        //     }
        //   }
        this.$router.push({
          path: this.localePath(redirectTo),
        });
      }
    },
  },
  components: {
    Input,
    CreditCardIcon,
    Button,
    EyeIcon,
    EyeOffIcon,
    Form,
    FormItem,
    SocialLoginBtns,
  },
};
