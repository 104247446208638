
import { Button, Dialog } from 'element-ui';
import { ChevronRightIcon } from 'vue-feather-icons';
import GetCardCadeauStep1 from './GetCardCadeauStep1.vue';
import GetCardCadeauStep2 from './GetCardCadeauStep2.vue';
import GetCardCadeauShow from './GetCardCadeauShow.vue';
import { LoyaltyService } from '@/helpers/LoyaltyService.js';
export default {
  props: {
    reward: {
      type: Object,
    },
  },
  data() {
    return {
      showDialog: false,
      step: 1,
      rewardReponse: null,
    };
  },
  mounted() {
    this.step = 1;
  },
  computed: {},
  methods: {
    close() {
      this.showDialog = false;
    },
    goToStep(step) {
      if (step < this.step && this.step != 3) {
        this.step = step;
      }
    },
    openDialog() {
      this.showDialog = true;
    },
    async saveReward(event) {
      console.log(event);
      this.rewardReponse = event;
      this.step = 2;
    },
    showReward() {
      this.step = 3;
      this.openDialog();
    },
  },
  created() {},
  components: {
    Button,
    Dialog,
    ChevronRightIcon,
    GetCardCadeauStep1,
    GetCardCadeauStep2,
    GetCardCadeauShow,
  },
};
