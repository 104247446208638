import { render, staticRenderFns } from "./PayzenForm.vue?vue&type=template&id=5a05a4e1&scoped=true"
import script from "./PayzenForm.vue?vue&type=script&lang=js"
export * from "./PayzenForm.vue?vue&type=script&lang=js"
import style0 from "./PayzenForm.vue?vue&type=style&index=0&id=5a05a4e1&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../nodevenv/public_html/de.jeanmarcphilippe.com/shop.digipart.fr/16/lib/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a05a4e1",
  null
  
)

export default component.exports