
import { FileTextIcon } from 'vue-feather-icons';

export default {
  computed: {
    adminBar() {
      const emailsText = process.env.DASHBOARD_EMAILS;
      if (emailsText) {
        const emails = emailsText.split(',');
        return emails.includes(this.$auth?.user?.Customer?.Email);
      }
      return false;
    },
    getLink() {
      return process.env.DASHBOARD_URL;
    },
    getCms() {
      return process.env.CMS_URL;
    },
  },
  components: {
    FileTextIcon,
  },
};
