
import { InfoIcon } from 'vue-feather-icons';
import { Button } from 'element-ui';
import { LoyaltyService } from '@/helpers/LoyaltyService.js';

export default {
  props: {
    reward: {
      type: Object,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async saveReward() {
      this.loading = true;
      const loyaltyService = new LoyaltyService(this.$http);
      try {
        const reponse = await loyaltyService.obtainReward({
          idLoyaltyReward: this.reward.IdLoyaltyReward,
          languageIsoCode: this.$i18n.locale,
        });

        this.rewardReponse = reponse;
        this.$store.dispatch('shop/loyalty/fetchLoyaltyCustomer');
        this.$store.dispatch('shop/loyalty/fetchEvents');
        this.$store.dispatch('shop/loyalty/fetchRewards');
        this.$store.dispatch('shop/loyalty/fetchMyRewards', {
          loyaltyRewardState: 'all',
        });

        this.$emit('onConfirme', this.rewardReponse); //0OUX1K
      } catch (error) {
      } finally {
        this.loading = false;
      }
    },
  },
  components: {
    InfoIcon,
    Button,
  },
};
