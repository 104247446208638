import { render, staticRenderFns } from "./Bar.vue?vue&type=template&id=79279b14&scoped=true"
import script from "./Bar.vue?vue&type=script&lang=js"
export * from "./Bar.vue?vue&type=script&lang=js"
import style0 from "./Bar.vue?vue&type=style&index=0&id=79279b14&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../nodevenv/public_html/de.jeanmarcphilippe.com/shop.digipart.fr/16/lib/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79279b14",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AdminBarLink: require('/home/jeanmarcphilippe/public_html/de.jeanmarcphilippe.com/shop.digipart.fr/components/admin/BarLink.vue').default})
