
import { ChevronDownIcon } from 'vue-feather-icons';
import LoyaltyPosts from '@/pages/account/components/LoyaltyPosts.vue';
import LoyaltyTop from '@/pages/account/components/LoyaltyTop.vue';
import { LoyaltyService } from '@/helpers/LoyaltyService';
import {
  Button,
  Progress,
  Radio,
  DatePicker,
  FormItem,
  Form,
} from 'element-ui';
export default {
  name: 'Loyalty',
  data() {
    return {
      loading: true,
      customer: {
        points: 0,
        state: false,
      },

      membershipForm: {
        birthDate: '',
      },
      rules: {
        birthDate: [
          {
            required: true,
            // message: this.$t('Required Field'),
            // trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    getIsMobile() {
      return this.$store.state.shared.wind.isMobile;
    },
  },
  methods: {
    subscribe() {
      const loyaltyService = new LoyaltyService(this.$http);
      loyaltyService
        .subscribe({
          birthdayDate: this.membershipForm.birthDate,
          languageIsoCode: this.$i18n.locale,
        })
        .then((data) => {
          this.$notify({
            title: this.$t('Success'),
            message: this.$t('You have successfully registered'),
            type: 'success',
          });
          this.customer.state = true;
          this.$router.push({
            path: this.localePath('/account/loyalty'),
          });
        })
        .catch((err) => {
          this.$notify.error({
            title: this.$t('Error'),
            message: err,
          });
        });
    },

    unsubscribe() {
      const loyaltyService = new LoyaltyService(this.$http);
      loyaltyService
        .unsubscribe({
          languageIsoCode: this.$i18n.locale,
        })
        .then((data) => {
          this.$notify({
            title: this.$t('Success'),
            message: this.$t('You have successfully unsubscribed'),
            type: 'success',
          });

          this.customer.state = false;
          this.$router.push({
            path: this.localePath('/account/loyalty'),
          });
        })
        .catch((err) => {
          this.$notify.error({
            title: this.$t('Error'),
            message: err,
          });
        });
    },
    onSubmit() {
      this.$refs['formMembership'].validate((valid) => {
        if (valid) {
          if (!this.customer.state) {
            this.subscribe();
          } else {
            this.unsubscribe();
          }
        } else {
          return false;
        }
      });
    },
    getLoyaltyData() {
      const loyaltyService = new LoyaltyService(this.$http);
      loyaltyService
        .loyaltyCustomer({
          languageIsoCode: this.$i18n.locale,
          currencyIsoCode: this.$localisation.appCurrency.CurrencyIsoCode,
        })
        .then((data) => {
          this.customer.points = data.TotalPoints;
          this.customer.state = data.Subscribed;
          this.membershipForm.birthDate = data.Birthday;
        })
        .catch((err) => {
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getLoyaltyData();
  },
  components: {
    ChevronDownIcon,
    LoyaltyPosts,
    LoyaltyTop,
    Progress,
    Button,
    Radio,
    DatePicker,
    FormItem,
    Form,
  },
};
